import React, { useState, useEffect, FC, PropsWithChildren } from 'react';
import { GraphOptions } from '../models/graph-options';
import Axios from 'axios';
import { WeatherSeries } from '../models/weather-series';
import { GraphAxis } from '../models/graph-axis';
import moment from 'moment';
import { COLUMN, LINE } from '../Utils/constants';
import Highcharts from 'highcharts';
import { Chart, ColumnSeries, HighchartsChart, HighchartsProvider, LineSeries, Tooltip, XAxis, YAxis } from 'react-jsx-highcharts';
import './weather-graph.scss';

export type WeatherDataType = 'Outside Temperature' | 'Temperature' | 'Humidity' | 'Barometer' | 'Solar Radiation' | 'High Wind Speed' | 'Average Wind Speed' | 'THSW Index'
    | 'Heat Index' | 'Wind Chill' | 'UV Index' | 'Rainfall' | 'Dewpoint';

interface WeatherGraphProps {
  weatherDataTypes: WeatherDataType[];
  allowGraphLoad: boolean;
  controllerRoot: string;
  endpoint: string;
  urlParams: string;
}

const WeatherGraph: FC<PropsWithChildren<WeatherGraphProps>> = ({weatherDataTypes, allowGraphLoad, controllerRoot, endpoint, urlParams}) => {
  const [graphOptions, setGraphOptions] = useState<GraphOptions[]>([]);
  const [weatherGraphSeries, setWeatherGraphSeries] = useState<WeatherSeries[]>([]);
  const [yAxises, setYAxises] = useState<GraphAxis[]>([]);

  useEffect(() => {
    const allOptions: GraphOptions[] = [
      {graphTitle: 'Outside Temperature', seriesTitle: 'Outside Temperature', color: 'var(--temperature-color)', id: 'temperature', label: '°', minValue: null, checked: true},
      {graphTitle: 'Temperature', seriesTitle: 'Temperature', color: 'var(--temperature-color)', id: 'temperature', label: '°', minValue: null, checked: true},
      {graphTitle: 'THSW Index', seriesTitle: 'THSW Index', color: 'var(--thsw-index-color)', id: 'thswIndex', label: '°', minValue: null, checked: false},
      {graphTitle: 'Heat Index', seriesTitle: 'Heat Index', color: 'var(--heat-index-color)', id: 'heatIndex', label: '°', minValue: null, checked: false},
      {graphTitle: 'Wind Chill', seriesTitle: 'Wind Chill', color: 'var(--wind-chill-color)', id: 'windChill', label: '°', minValue: null, checked: false},
      {graphTitle: 'Humidity', seriesTitle: 'Humidity', color: 'var(--humidity-color)', id: 'humidity', label: '%', minValue: 0, checked: false},
      {graphTitle: 'Dewpoint', seriesTitle: 'Dewpoint', color: 'var(--dew-point-color)', id: 'dewpoint', label: '°', minValue: null, checked: false},
      {graphTitle: 'UV Index', seriesTitle: 'UV Index', color: 'var(--uv-index-color)', id: 'uvIndex', label: '', minValue: 0, checked: false},
      {graphTitle: 'Barometer', seriesTitle: 'Barometer', color: 'var(--barometer-color)', id: 'barometer', label: ' In.', minValue: null, checked: false},
      {graphTitle: 'Solar Radiation', seriesTitle: 'Solar Radiation', color: 'var(--solar-radiation-color)', id: 'solarRadiation', label: ' W/M2', minValue: 0, checked: false},
      {graphTitle: 'High Wind Speed', seriesTitle: 'High Wind Speed', color: 'var(--high-wind-speed-color)', id: 'highWind', label: ' MPH', minValue: 0, checked: false},
      {graphTitle: 'Average Wind Speed', seriesTitle: 'Average Wind Speed', color: 'var(--average-wind-speed-color)', id: 'averageWind', label: ' MPH', minValue: 0, checked: false},
      {graphTitle: 'Rainfall', seriesTitle: 'Rainfall', color: 'var(--rainfall-color)', id: 'rainfall', label: ' In.', minValue: 0, checked: false}
    ];

    const loadGraphOptions = () => {
      const options: GraphOptions[] = [];

      weatherDataTypes.forEach((weatherType) => {
        const option = allOptions.find((weatherDataType) => weatherDataType.graphTitle === weatherType);

        if (option) {
          options.push(option);
        }
      })
      
      setGraphOptions(options);
    };

    loadGraphOptions();
  }, [weatherDataTypes]);

  useEffect(() => {
    const removeGraphData = () => {
      const unSelectedWeatherTypes = graphOptions.filter((rec) => !rec.checked);
      const graphSeries = [...weatherGraphSeries];
      const yAxisesCopy = [...yAxises];
      let updated = false;
      let seriesUpdated = false;

      unSelectedWeatherTypes.forEach((type) => {
        const index = graphSeries.findIndex((series) => series.id === type.id);
        if (index >= 0) {
          const seriesYAxis = graphSeries.filter((ya) => ya.id === graphSeries[index].yAxis);

          if (seriesYAxis.length === 1) {
            const seriesYAxisIndex = yAxisesCopy.findIndex((ya) => ya.id === graphSeries[index].yAxis);
            yAxisesCopy.splice(seriesYAxisIndex, 1);
            seriesUpdated = true;
          }
          graphSeries.splice(index, 1);
          updated = true;
        }
      });

      if (updated) {
        setWeatherGraphSeries(graphSeries);
      }

      if (seriesUpdated) {
        setYAxises(yAxisesCopy);
      }
    }

    const addGraphData = () => {
      const selectedWeatherTypes = graphOptions.filter((rec) => {
        return rec.checked;
      });

      for (let i = 0; i < selectedWeatherTypes.length; i++) {
        const weatherType = selectedWeatherTypes[i].id;
        const yAxis = selectedWeatherTypes[i].label;

        if (weatherGraphSeries.filter((series) => series.id === weatherType).length === 0) {
          const weatherRec = graphOptions.find((elm) => { return elm.id === weatherType; });

          const weatherDataUrl = `${controllerRoot}${endpoint}/${urlParams}${urlParams ? '/' : ''}${weatherType}`;

          // eslint-disable-next-line no-loop-func
          Axios.get(weatherDataUrl).then((response) => {
            const graphData: number[][] = [];

            console.log(response);
            response.data.values.forEach((rec: any) => {
              const time = moment(rec.time).valueOf();
              graphData.push([time, rec.value]);
            });

            if (yAxises.filter((ya) => ya.id === yAxis).length === 0) {
              const chartYAxises = [...yAxises];

              const newAxis: GraphAxis = {
                id: yAxis,
                opposite: chartYAxises.length % 2 > 0,
                label: weatherRec?.label ? weatherRec.label : ''
              }

              if (selectedWeatherTypes[i].minValue) {
                newAxis.min = selectedWeatherTypes[i].minValue ?? undefined;
              }

              if (weatherType === 'humidity') {
                newAxis.max = 100;
              }

              if (weatherType === 'rainfall') {
                newAxis.tickInterval = 0.01;
              }

              setYAxises([...yAxises, newAxis]);
            }

            const newSeries: WeatherSeries = {
              name: weatherRec?.seriesTitle ? weatherRec.seriesTitle : '',
              color: weatherRec?.color ? weatherRec.color : '#FFFFFF',
              data: graphData,
              yAxis: yAxis,
              id: weatherType
            };

            if (weatherType === 'rainfall') {
              newSeries.type = COLUMN;
            } else {
              newSeries.type = LINE;
            }

            setWeatherGraphSeries([...weatherGraphSeries, newSeries]);
          });
        }
      }
    }

    if (allowGraphLoad) {
      addGraphData();
      removeGraphData();
    }
  }, [allowGraphLoad, graphOptions, weatherGraphSeries, yAxises, controllerRoot, urlParams, endpoint]);

  const updateGraph = (id: string) => {
    const options = [...graphOptions];
    const option = options.find((elm) => { return elm.id === id; });

    if (option) {
      if (option?.checked) {
        option.checked = false;
      } else {
        option.checked = true;
      }

      setGraphOptions([...options]);
    }
  };

  return (
    <div>
      <div className='graphTypeSelectionContainer'>
        {graphOptions.map((option) => (
          <div className='checkboxContainer' key={option.id}>
            <input type='checkbox' checked={option.checked} onChange={() => { updateGraph(option.id); }} />
            <span className={option.id}>{option.seriesTitle}</span>
          </div>))
        }
      </div>
      <div className='graphContainer'>
        <HighchartsProvider Highcharts={Highcharts}>
          <HighchartsChart time={{ timezone: 'America/Denver' }}>
            <Chart height={285} width={652} />
            <Tooltip shared={true} />
            <XAxis
              type="datetime"
              labels={{
                style: { color: '#707073' }
              }}
            />
            {yAxises.map((yAxis) => (
              <YAxis id={yAxis.id}
                key={yAxis.id}
                opposite={yAxis.opposite}
                min={yAxis.min}
                max={yAxis.max}
                tickInterval={yAxis.tickInterval}
                labels={{
                  format: '{text}' + yAxis.label,
                  style: { color: '#707073' }
                }}>
                {weatherGraphSeries.filter((series) => series.yAxis === yAxis.id).map((graph) => {
                  if (graph.type === LINE) {
                    return <LineSeries
                      key={graph.name}
                      data={graph.data}
                      name={graph.name}
                      color={graph.color}
                      yAxis={graph.yAxis} />;
                  } else {
                    return <ColumnSeries
                      key={graph.name}
                      data={graph.data}
                      name={graph.name}
                      color={graph.color}
                      yAxis={graph.yAxis} />;
                  }
                })}
              </YAxis>
            ))}
          </HighchartsChart>
        </HighchartsProvider>
      </div>
    </div>
  )
};

export default WeatherGraph;
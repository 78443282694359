import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as moment from 'moment';
import './cwop-station.scss';
import ReactGA from 'react-ga';
import { formatOnePlace, formatThreePlaces, formatTwoPlaces } from '../Utils/utilities';
import WeatherGraph, { WeatherDataType } from '../common/weather-graph';

const CwopStation = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [weatherData, setWeatherData] = useState<any>({});
  const [allowGraphLoad, setAllowGraphLoad] = useState(false);

  const weatherDataTypes: WeatherDataType[] = [
    'Outside Temperature',
    'Humidity',
    'Barometer',
    'Solar Radiation',
    'High Wind Speed',
    'Average Wind Speed'
  ];

  useEffect(() => {
    ReactGA.pageview('CWOPStation');

    const getCurrentData = () => {
      const callSign = props.match.params.callSign ? props.match.params.callSign : 'cw2323';
      const currentWeatherUrl = `${process.env.REACT_APP_CWOP_CONTROLLER_ROOT}GetWeatherStationLastRecord?callSign=${callSign}`;

      axios.get(currentWeatherUrl)
        .then(response => {
          const momentDate = moment.utc(response.data.receiveDate);
          const momentUtcString = momentDate.format('MMMM Do YYYY, h:mm a');

          response.data.recordDate = moment.utc(response.data.receiveDate)
            .add(response.data.hoursOffset, 'hours')
            .format('MMMM Do YYYY, h:mm a') + ` (${momentUtcString} UTC)`;
          setLoading(false);
          setWeatherData(response.data);
          setAllowGraphLoad(true);
        });
    }

    getCurrentData();
  }, [props.match.params.callSign]);

  const calculateWindDirection = (degrees: number) => {
    //N
    if (degrees >= 350 || degrees <= 11) {
      return 'N';
    }
    //NNE
    else if (degrees >= 12 && degrees <= 34) {
      return 'NNE';
    }
    //NE
    else if (degrees >= 35 && degrees <= 56) {
      return 'NE';
    }
    //ENE
    else if (degrees >= 57 && degrees <= 79) {
      return 'ENE';
    }
    //E
    else if (degrees >= 80 && degrees <= 101) {
      return 'E';
    }
    //ESE
    else if (degrees >= 102 && degrees <= 124) {
      return 'ESE';
    }
    //SE
    else if (degrees >= 125 && degrees <= 146) {
      return 'SE';
    }
    //SSE
    else if (degrees >= 147 && degrees <= 169) {
      return 'SSE';
    }
    //S
    else if (degrees >= 170 && degrees <= 191) {
      return 'S';
    }
    //SSW
    else if (degrees >= 192 && degrees <= 214) {
      return 'SSW';
    }
    //SW
    else if (degrees >= 215 && degrees <= 236) {
      return 'SW';
    }
    //WSW
    else if (degrees >= 237 && degrees <= 259) {
      return 'WSW';
    }
    //W
    else if (degrees >= 260 && degrees <= 281) {
      return 'W';
    }
    //WNW
    else if (degrees >= 282 && degrees <= 304) {
      return 'WNW';
    }
    //NW
    else if (degrees >= 305 && degrees <= 326) {
      return 'NW';
    }
    //NNW
    else if (degrees >= 327 && degrees <= 349) {
      return 'NNW';
    } else {
      return '---';
    }
  }

  const convertMbToIn = (mb: number) => {
    return (mb * 0.0295301);
  }

  const calculateDewpoint = (humidity: number, temperature: number) => {
    if (humidity && temperature) {
      const tempC = (temperature - 32.0) * 5.0 / 9.0;
      const vaporPressure = humidity * 0.01 * 6.112 * Math.exp((17.62 * tempC) / (tempC + 243.12));
      const numerator = 243.12 * Math.log(vaporPressure) - 440.1;
      const denominator = 19.43 - (Math.log(vaporPressure));
      const dewPoint = numerator / denominator;

      return formatOnePlace(dewPoint * 1.8 + 32.0);
    }
  }

  return (
    <div className='dataContainer'>
      <div className='centeringDiv'>
        {loading &&
          <div className='overlay'>
            <div className='spinnerContainer'>
              <span className='spinner fa fa-spinner fa-spin'></span>
              <span className='loadingText'>Loading...</span>
            </div>
          </div>
        }
        <div className='mainSummaryBox'>
          <div className='lastUpdateDiv'>
            Last Update: {weatherData.recordDate}
          </div>

          {/************************ Temperature **********************************************************/}
          <div className='temperatureSummaryDiv'>
            <div className='weatherSummaryDiv'>
              <div className='summaryLabelDiv'>
                Temperature
              </div>
              <div className='currentTemperatureDiv'>{weatherData.temperature}&deg;</div>
              <div className='weatherDataDiv'>
                <div className='weatherValueDiv'>{weatherData.heatIndex}&deg;</div>
                Heat Index:
              </div>
              <div className='weatherDataDiv'>
                <div className='weatherValueDiv'>{weatherData.windChill}&deg;</div>
                Wind Chill:
              </div>
            </div>

            <div className='spaceDivSmall'></div>
            <div className='highLowValuesSpacer'></div>
            <div className='highLowValuesColumn boldText'>
              Today
            </div>
            <div className='highLowValuesColumn boldText'>
              Yesterday
            </div>
            <div className='spaceDivSmall'></div>
            <div className='highLowVColumn'>
              High:
            </div>
            <div className='highLowValuesColumn'>
              {weatherData.todayHighTemperature}&deg;
            </div>
            <div className='highLowValuesColumn'>
              {weatherData.yesterdayHighTemperature}&deg;
            </div>
            <div className='highLowValuesColumn'>
              {moment.utc(weatherData.todayHighTemperatureTime).add(weatherData.hoursOffset, 'hours').format('h:mm a')}
            </div>
            <div className='highLowValuesColumn'>
              {moment.utc(weatherData.yesterdayHighTemperatureTime).add(weatherData.hoursOffset, 'hours').format('h:mm a')}
            </div>

            <div className='spaceDivSmall'></div>
            <div className='highLowVColumn'>
              Low:
            </div>
            <div className='highLowValuesColumn'>
              {weatherData.todayLowTemperature}&deg;
            </div>
            <div className='highLowValuesColumn'>
              {weatherData.yesterdayLowTemperature}&deg;
            </div>
            <div className='highLowValuesColumn'>
              {moment.utc(weatherData.todayLowTemperatureTime).add(weatherData.hoursOffset, 'hours').format('h:mm a')}
            </div>
            <div className='highLowValuesColumn'>
              {moment.utc(weatherData.yesterdayLowTemperatureTime).add(weatherData.hoursOffset, 'hours').format('h:mm a')}
            </div>
          </div>

          {/************************ Wind **********************************************************/}
          <div className='weatherSummaryDiv'>
            <div className='summaryLabelDiv'>
              Wind
            </div>
            <div className='weatherDataTopDiv'>
              <div className='weatherValueDiv'>{calculateWindDirection(weatherData.windDirection) + ' (' + weatherData.windDirection + '°)'}</div>
              Direction:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{weatherData.windSpeed} mph</div>
              Average Speed:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{weatherData.windGust} mph</div>
              Gust Speed:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{weatherData.todayWindGust} mph at {moment.utc(weatherData.todayWindGustTime).add(weatherData.hoursOffset, 'hours').format('h:mm a')}</div>
              Today:
            </div>
            <div className='weatherDataDiv'>
              &nbsp;
            </div>
          </div>

          {/****************************** Rain *************************************/}
          <div className='weatherSummaryDiv'>
            <div className='summaryLabelDiv'>
              Precipitation
            </div>
            <div className='weatherDataTopDiv'>
              <div className='weatherValueDiv'>{formatTwoPlaces(weatherData.rainToday)} in.</div>
              Today's Rain:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{formatTwoPlaces(weatherData.rainLastHour)} in.</div>
              Rain Last Hour:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{formatTwoPlaces(weatherData.rain24Hours)} in.</div>
              Rain Last 24 Hours:
            </div>
          </div>

          {/************** Humidity *********************************/}
          <div className='weatherSummaryDiv'>
            <div className='summaryLabelDiv'>
              Humidity / Barometer
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{weatherData.humidity}%</div>
              Humidity:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{calculateDewpoint(weatherData.humidity, weatherData.temperature)}&deg;</div>
              Dewpoint:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{formatThreePlaces(convertMbToIn(weatherData.barometer))} in.</div>
              Barometer:
            </div>
          </div>

          {/************** Solar *********************************/}
          <div className='weatherSummaryDiv'>
            <div className='summaryLabelDiv'>
              Solar
            </div>
            <div className='weatherDataDiv'>
              {weatherData.solarRadiation !== null ?
                (
                  <div className='weatherValueDiv'>{weatherData.solarRadiation} W/m<sup>2</sup></div>
                ) :
                (
                  <div className='weatherValueDiv'>No Solar Data</div>
                )
              }
              Solar Radiation:
            </div>
          </div>

        </div>
        <div>
          <WeatherGraph
            allowGraphLoad={allowGraphLoad}
            weatherDataTypes={weatherDataTypes}
            controllerRoot={process.env.REACT_APP_CWOP_CONTROLLER_ROOT as string}
            urlParams={`${props.match.params.callSign}/24`}
            endpoint="GetCWOPGraphData"
          >
          </WeatherGraph>
        </div>
      </div>
    </div>
  );
}

export default CwopStation;
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import { IndoorData } from '../models/indoor-data';
import WeatherGraph, { WeatherDataType } from '../common/weather-graph';

const IndoorWeather = () => {
  const [currentData, setCurrentData] = useState<IndoorData>();
  const [allowGraphLoad, setAllowGraphLoad] = useState(false);
  let { location } = useParams<any>();

  const weatherDataTypes: WeatherDataType[] = [
    'Temperature',
    'Humidity',
    'Dewpoint',
    'Barometer'
  ];

  if (!location) {
    location = 'Garage';
  }

  useEffect(() => {
    const getCurrentData = () => {
      const currentWeatherUrl = `${process.env.REACT_APP_HOME_CONTROLLER_ROOT}GetIndoorData/${location}`;

      axios.get<IndoorData>(currentWeatherUrl)
        .then((response) => {
          setCurrentData(response.data);
          setAllowGraphLoad(true);
        });
    }

    getCurrentData();
  }, [location]);

  return (
    <div>
      {currentData &&
        <div>
          <div>
            Record Time: {moment.utc(currentData.recordDate).local().format('MMM DD, YYYY h:mm A')}
          </div>
          <div>
            Temperature: {currentData.temperature}&deg; F
          </div>
          <div>
            Dewpoint: {currentData.dewpoint}&deg; F
          </div>
          <div>
            Humidity: {currentData.humidity}%
          </div>
          <div>
            Barometer: {currentData.barometer}mb ({(currentData.barometer * 0.02952998).toFixed(3)}in)
          </div>

          <div>
            <WeatherGraph
              allowGraphLoad={allowGraphLoad}
              weatherDataTypes={weatherDataTypes}
              controllerRoot={process.env.REACT_APP_HOME_CONTROLLER_ROOT as string}
              urlParams={location}
              endpoint="GetIndoorGraphData"
            >
            </WeatherGraph>
          </div>
        </div>
      }
    </div>
  );
}

export default IndoorWeather;
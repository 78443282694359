import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import './home.scss';
import ReactGA from 'react-ga';
import { formatOnePlace, formatThreePlaces, formatTwoPlaces } from '../Utils/utilities';
import WeatherGraph, { WeatherDataType } from '../common/weather-graph';

const HomePage = () => {
  const [loading, setLoading] = useState(true);
  const [weatherData, setWeatherData] = useState<any>({});
  const [allowGraphLoad, setAllowGraphLoad] = useState(false);

  const weatherDataTypes: WeatherDataType[] = [
    'Outside Temperature',
    'THSW Index',
    'Heat Index',
    'Wind Chill',
    'Humidity',
    'Dewpoint',
    'UV Index',
    'Barometer',
    'Solar Radiation',
    'High Wind Speed',
    'Average Wind Speed',
    'Rainfall'
  ];

  useEffect(() => {
    ReactGA.pageview('Home');

    const getCurrentData = () => {
      const currentWeatherUrl = `${process.env.REACT_APP_HOME_CONTROLLER_ROOT}GetWeatherData`;

      axios.get(currentWeatherUrl)
        .then(response => {
          const momentDate = moment(response.data.recordDate);
          response.data.recordDate = momentDate.format('MMMM Do YYYY, h:mm a');
          setAllowGraphLoad(true);
          setLoading(false);
          setWeatherData(response.data);
        });
    }

    getCurrentData();
  }, []);

  const getChangeTempClass = () => {
    if (weatherData && weatherData.temperature24HourChange) {
      if (weatherData.temperature24HourChange > 0) {
        return 'redText';
      } else if (weatherData.temperature24HourChange < 0) {
        return 'blueText';
      }
    }

    return '';
  }

  return (
    <div className='dataContainer'>
      <div className='centeringDiv'>
        {loading &&
          <div className='overlay'>
            <div className='spinnerContainer'>
              <span className='spinner fa fa-spinner fa-spin'></span>
              <span className='loadingText'>Loading...</span>
            </div>
          </div>
        }
        <div className='mainSummaryBox'>
          <div className='lastUpdateDiv'>
            Last Update: {weatherData.recordDate} Mountain Time
          </div>

          {/************************ Temperature **********************************************************/}
          <div className='temperatureSummaryDiv'>
            <div className='weatherSummaryDiv'>
              <div className='summaryLabelDiv'>
                Temperature
              </div>
              <div className='currentTemperatureDiv'>{weatherData.temperature}&deg;</div>
              <div className='weatherDataDiv'>
                <div className='weatherValueDiv'>{weatherData.thswIndex}&deg;</div>
                THSW Index:
              </div>
              <div className='weatherDataDiv'>
                <div className='weatherValueDiv'>{weatherData.heatIndex}&deg;</div>
                Heat Index:
              </div>
              <div className='weatherDataDiv'>
                <div className='weatherValueDiv'>{weatherData.windChill}&deg;</div>
                Wind Chill:
              </div>
              <div className='weatherDataDiv'>
                <div className={'weatherValueDiv ' + getChangeTempClass()}>{formatOnePlace(weatherData.temperature24HourChange)}&deg;</div>
                24 Hour Chg:
              </div>
            </div>

            <div className='spaceDivSmall'></div>
            <div className='highLowValuesSpacer'></div>
            <div className='highLowValuesColumn boldText'>
              Today
            </div>
            <div className='highLowValuesColumn boldText'>
              Yesterday
            </div>
            <div className='spaceDivSmall'></div>
            <div className='highLowVColumn'>
              High:
            </div>
            <div className='highLowValuesColumn'>
              {weatherData.highTemperatureToday}&deg;
            </div>
            <div className='highLowValuesColumn'>
              {weatherData.highTemperatureYesterday}&deg;
            </div>
            <div className='highLowValuesColumn'>
              {moment(weatherData.highTemperatureTimeToday).format('h:mm a')}
            </div>
            <div className='highLowValuesColumn'>
              {moment(weatherData.highTemperatureTimeYesterday).format('h:mm a')}
            </div>

            <div className='spaceDivSmall'></div>
            <div className='highLowVColumn'>
              Low:
            </div>
            <div className='highLowValuesColumn'>
              {weatherData.lowTemperatureToday}&deg;
            </div>
            <div className='highLowValuesColumn'>
              {weatherData.lowTemperatureYesterday}&deg;
            </div>
            <div className='highLowValuesColumn'>
              {moment(weatherData.lowTemperatureTimeToday).format('h:mm a')}
            </div>
            <div className='highLowValuesColumn'>
              {moment(weatherData.lowTemperatureTimeYesterday).format('h:mm a')}
            </div>
          </div>

          {/************************ Wind **********************************************************/}
          <div className='weatherSummaryDiv'>
            <div className='summaryLabelDiv'>
              Wind
            </div>
            <div className='weatherDataTopDiv'>
              <div className='weatherValueDiv'>{weatherData.windDirection}</div>
              Direction:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{weatherData.windSpeed} mph</div>
              Average Speed:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{weatherData.windGust} mph</div>
              Gust Speed:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{weatherData.highWindGust} mph at {moment(weatherData.highWindGustTime).format('h:mm a')}</div>
              Today:
            </div>
            <div className='weatherDataDiv'>
              &nbsp;
            </div>
          </div>

          {/****************************** Rain *************************************/}
          <div className='weatherSummaryDiv'>
            <div className='summaryLabelDiv'>
              Precipitation
            </div>
            <div className='weatherDataTopDiv'>
              <div className='weatherValueDiv'>{formatTwoPlaces(weatherData.rainToday)} in.</div>
              Today's Rain:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{formatTwoPlaces(weatherData.highRainRate)} in.</div>
              High Rain Rate (/hr):
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{formatTwoPlaces(weatherData.rainYesterday)} in.</div>
              Yesterday's Rain:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{formatTwoPlaces(weatherData.snowfallToday)} in.</div>
              Today's Snowfall*:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{formatTwoPlaces(weatherData.snowfallYesterday)} in.</div>
              Yesterday's Snowfall:
            </div>
          </div>

          {/************** Humidity *********************************/}
          <div className='weatherSummaryDiv'>
            <div className='summaryLabelDiv'>
              Humidity / Barometer
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{weatherData.humidity}%</div>
              Humidity:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{weatherData.dewpoint}&deg;</div>
              Dewpoint:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{formatThreePlaces(weatherData.barometer)} in.</div>
              Barometer:
            </div>
          </div>

          {/************** Solar *********************************/}
          <div className='weatherSummaryDiv'>
            <div className='summaryLabelDiv'>
              Solar
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{weatherData.uvIndex}</div>
              UV Index:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{weatherData.solarRadiation} W/m<sup>2</sup></div>
              Solar Radiation:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{formatTwoPlaces(weatherData.solarEnergy)} Langleys</div>
              Solar Energy:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{formatThreePlaces(weatherData.evapoTransparation)} in.</div>
              Evapo-Trans:
            </div>
          </div>
        </div>

        {/************************* Graph ******************************************/}
        <div>
          <WeatherGraph
            allowGraphLoad={allowGraphLoad}
            weatherDataTypes={weatherDataTypes}
            controllerRoot={process.env.REACT_APP_HOME_CONTROLLER_ROOT as string}
            urlParams=""
            endpoint="GetWeatherGraphData"
          >
          </WeatherGraph>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
